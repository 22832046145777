<template>
  <order-form :url="url" result-router-name="kdresult" business-type="kd"/>
</template>

<script>
import OrderForm from './../page/form';
import Api from './api';
export default {
  name: 'OrderFormVue',
  components: {
    OrderForm
  },
  data () {
    return {
      url: Api.PREFIX
    };
  },
  mounted () {
  },
  created () {
  },
  watch: {
  },
  computed: {
  },
  methods: {

  },
  beforeDestroy() {
  }
};
</script>
<style scoped  lang="scss">
</style>
<style>
</style>
